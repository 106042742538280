import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import handleNavClick from './navMenu.js';
import ReactGA from 'react-ga';

// Lazy loading for components
const Home = lazy(() => import('./components/Home'));
const Roadmap = lazy(() => import('./components/Roadmap'));
const Community = lazy(() => import('./components/Community'));
const Tokenomics = lazy(() => import('./components/Tokenomics'));

library.add(fab, fas);

function App() {
  useEffect(() => {
    handleNavClick();
    ReactGA.initialize('YOUR_TRACKING_ID');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
          <div className="container">
            <Link className="navbar-brand d-flex align-items-center" to="/">
              EatNot
            </Link>
            <button 
              className="navbar-toggler" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target="#navbarNav" 
              aria-controls="navbarNav" 
              aria-expanded="false" 
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/roadmap">Road Map</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/tokenomics">Tokenomics</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/community">Community</Link></li>
              </ul>
            </div>
          </div>
        </nav>

        {/* Ana Sayfa İçeriği */}
        <div className="container-fluid">
          <div className="row">
            {/* Boş bir alan bırakabilirsiniz veya buraya başka bir içerik ekleyebilirsiniz */}
          </div>
        </div>

        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/tokenomics" element={<Tokenomics />} />
            <Route path="/community" element={<Community />} />
          </Routes>
        </Suspense>

        {/* Footer */}
        <footer className="bg-dark text-light text-center py-3">
          <div className="container">
            <p>© 2024 EatNot. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;