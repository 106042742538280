function handleNavClick() {
  const navLinks = document.querySelectorAll('.nav-link');
  const menu = document.querySelector('.navbar-collapse');

  navLinks.forEach(link => {
    link.addEventListener('click', () => {
      if (menu.classList.contains('show')) {
        menu.classList.remove('show');
      }
    });
  });
}

export default handleNavClick;